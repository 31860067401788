.preloader-container {
    position: absolute;
    z-index: 100;

    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: var(--primary-color);

    transition: all 2s ease;
}

.preloader-container.hide {
    opacity: 0;
    filter: blur(5px);
    visibility: hidden;
}

.preloader-container div {


    font-size: clamp(20px, 3vw, 40px);
    color: var(--primary-color);
    position: relative;

    transition: all 3s ease;
    filter: blur(5px);
}

.preloader-container div.show {
    color: black;
    filter: none;
    transform: scale(1.2);
}



