.footer-container {

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 30vh;
    background-color: var(--primary-color);

    .top {
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        >div {
            width: 30%;
        }

        .logo {
            font-size: 2rem;
            display: flex;
            flex-direction: column;
            align-self: center;
            margin-left: 1.5rem;

            .two {
                display: flex;

                div {
                    margin-left: 0.5rem;
                }
            }

            >div {
                width: fit-content;
                transition: all 0.6s ease;

                &:hover {
                    color: white;
                    cursor: default;
                }
            }
        }

        .get-quote {
            text-align: center;
            align-self: center;

            border: 2px solid black;
            border-radius: 10px;
            padding: 0.5rem;

            transition: all 0.3s ease;

            &:hover {
                cursor: pointer;
                background-color: #9ac0ee;
                color: white;
                border-color: white;
                transform: scale(1.2);
            }
        }

        .links {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-right: 1.5rem;

            >div {
                padding: 0.2rem;
                width: fit-content;

                transition: all 0.6s ease;
                transition-delay: 0s;

                &:hover {
                    color: white;
                    cursor: pointer;
                }
            }
        }
    }

    .bottom {
        text-align: center;
    }
}

@media screen and (max-width: 550px) {
    .footer-container {
        height: 220px;
        justify-content: space-between;

        .top {
            margin-top: 10px;

            >div {
                font-size: 0.75rem;
                width: 20%;
                padding: 0;
            }

            .logo {
                font-size: 1.25rem;
                margin-left: 0;
                gap: 2px;

                .two {
                    div {
                        margin-left: 0.25rem;
                    }
                }
            }

            .links {
                margin-right: 0;
            }
        }

        .bottom {
            text-align: center;
            width: 60%;
            align-self: center;
            margin-bottom: 20px;
        }
    }
}

@media screen and (orientation: landscape) and (max-width: 768px) {
    .footer-container {
        height: auto;

        .top {
            margin-top: 2rem;
            margin-left: 1rem;
            margin-right: 1rem;
            margin-bottom: 1rem;

            >div {
                width: 30%;
            }
        }

        .bottom {
            margin-bottom: 1rem;
        }
    }
}