.hero-container {
    opacity: 0;
    filter: blur(10px);

    transition: all 2s ease;
    overflow: hidden;
    will-change: opacity, transform;
}

.hero-container.hide {
    opacity: 0;
    filter: none;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    height: 100vh;
    color: white;
    font-size: 6vw;
    background:
        linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(0, 0, 0)),
        url('../../assets/skyline-downtown-charlotte-north-carolina.jpg') center center/cover;
    background-position: top;

    position: relative;
    z-index: 1;
}

.hero-container.show {
    opacity: 1;
}

.hero-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background:
        linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(0, 0, 0)),
        url('../../assets/skyline-downtown-charlotte-north-carolina.jpg') center center/cover;
    filter: blur(2.75px);
    z-index: -1;
}

.company-name {

    font-size: clamp(1vw, 5vw, 60px);

    transform: translateX(-200%);

    transition: all 2s ease-out;

    will-change: transform;

    .two {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .fa-truck-fast {
        margin-right: 1rem;
    }
}

.company-name.show {
    transform: translateX(0);
}

.hero-slogan {
    font-size: clamp(1vw, 5vw, 60px);
    font-style: italic;

    
    padding: 10px;
    border-radius: 15px;

    transform: translateX(200%);

    transition: transform 2s ease-out;

    will-change: transform;

    &:hover {
        border: 1px solid rgb(242, 136, 55);
        padding: 9px;
    }
}

.hero-slogan.show {
    transform: translateX(0);
}

@media screen and (max-width: 550px){
    .hero-container.show {    
        flex-direction: column;
        justify-content: space-evenly;
    
        height: 100vh;
        font-size: 6vw;
    }

    .company-name {

        font-size: clamp(30px, 50px, 60px);
        text-align: center;

        transition: all 1.5s ease-out;
        
        .two {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    
        .fa-truck-fast {
            margin-right: 1rem;
        }
    }
    
    .hero-slogan {
        font-size: clamp(20px, 35px, 45px);  
        
        transition: transform 2s ease-out;
    
        &:hover {
            border: none;
            padding: none;
        }

        &:active {
            border: 1px solid rgb(242, 136, 55);
            padding: 9px;
        }
    }
}