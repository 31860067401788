.services-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    border: none;
    background-color: black;

    color: white;
    text-align: center;

    .title {
        color: white;
        font-size: 3rem;
        align-self: center;

        margin: 5rem;
    }

    .contents {
        display: grid;
        height: 60%;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);

        .service {

            transition: all 0.3s ease;
            transition-delay: 0.075s;

            &:hover {
                transform: translateY(-15px);
                color: var(--primary-color);
            }
        }

        .logo {
            font-size: 3rem;
        }

    }
}

@media screen and (max-width: 550px) {
    .contents {
        width: 90%;
        align-self: center;
        font-size: 0.7rem;
    }
}

@media screen and (orientation: landscape) and (max-width: 768px) {
    .services-container {
        height: auto;
    
        .title {
            font-size: 3rem;    
            /* margin: 2rem; */
        }
    
        .contents {
            display: grid;
            height: auto;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(3, 1fr);
            gap: 2rem;
            margin-bottom: 6rem;
    
            .service {
    
                transition: all 0.3s ease;
                transition-delay: 0.075s;
    
                &:hover {
                    transform: translateY(-15px);
                    color: var(--primary-color);
                }
            }
    
            .logo {
                font-size: 2rem;
            }
    
        }
    }
}