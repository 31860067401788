.contact-container {
    height: 100vh;
    color: white;
    background-color: black;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    position: relative;
    z-index: 1;

    filter: hue-rotate(155deg);
}

.contact-container::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 10px;
    width: 100%;
    height: 100%;

    background:
        url('../../assets/contactUs.png') no-repeat top 35px right/cover;
    filter: blur(2.5px);
    z-index: -1;
}

.contact-container .title {
    color: white;
    font-size: 3rem;
    align-self: center;
}

.our-email-button {
    /* background-color: var(--primary-color); */
    position: relative;
    filter: hue-rotate(195deg);
    height: auto;
    width: 150px;
    padding: 5px;
    line-height:20px;
    text-align: center;
    align-content: center;
    background-color: black;
    overflow: hidden;
    border: 1px solid rgb(255, 255, 255, 0.432);
    border-left: none;
    border-radius: 0 5px 5px 0;
    box-shadow: 0 0 5px rgba(0, 255, 255, 0.8), 0 0 5px rgba(0, 255, 255, 0.6), 0 0 10px rgba(0, 255, 255, 0.4);
    cursor: pointer;

    transition: all 1s ease 0.2s;
}

.our-email-button.show {
    width: 100vw;
}

.our-email-button span {
    background-color: black;
}

.our-email-button div {
    transition: opacity 0.7s ease 1s;
}

.our-email-button span {
    position: absolute;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    text-align: center;

    transition: opacity 0.7s ease;
}

.our-email-button.show div {
    transition: opacity 0.7s ease;
    opacity: 0;
}

.our-email-button.show span {
    transition: opacity 0.7s ease 1s;
    opacity: 1;
}



.contact__form {
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    letter-spacing: 2px;
}

.form-section-top {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.form-section-bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contact__form .form-section-left {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
}

.contact__form .form-section-right {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
}

.form-div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.contact__form input {
    border-radius: 5px;
    border: none;
    height: 25px;
    padding: 0.5rem;
    color: #494949;
    letter-spacing: 2px;

    transition: background-color 0.3s ease;
}

.contact__form input:focus {
    outline: none;
}

.form-div.message {
    height: 100%;
}

.contact__form textarea {
    border-radius: 5px;
    padding: 0.5rem;
    height: 100%;
    border: none;
    color: #494949;

    transition: all 0.3s ease;
}

.contact__form textarea:focus {
    outline: none;
}

.contact__form button {
    border-radius: 5px;
    border: none;
    height: 40px;
    width: 8rem;
    align-self: center;
    letter-spacing: 2px;
    background-color: white;
    color: black;

    transition: all 0.3s ease;
}

.contact__form button:hover {
    cursor: pointer;
    background-color: var(--primary-color);
    transition-delay: 0.1s;
    transform: scale(1.2);
}

.contact__form button:disabled {
    background-color: rgba(153, 153, 153, 0.483);
    color: white;
    transform: none;
}

.contact__form button:disabled:hover {
    background-color: var(--secondary-color);
}

.swal-confirm-button {
    background-color: var(--primary-color);







}

@media screen and (max-width: 550px){

    label {
        font-size: 0.75rem;
    }

    .contact-container {
        height: 150vh;
        padding-top: 3rem;
    }
    
    .contact-container::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 10px;
        width: 100%;
        height: 100%;
    
        background:
            url('../../assets/contactUsMobile.png') no-repeat top 110px right/cover;
        filter: blur(2.5px);
        z-index: -1;
    }

    .contact__form {
        height: auto;
        margin-top: 4rem;
    }
    
    .form-section-top {
        flex-direction: column;
        align-items: center;
        gap: 4rem;
    }

    .form-section-left input {
        font-size: 16px;
    }

    .form-section-right textarea {
        font-size: 16px;
    }

    .form-section-bottom {
        margin-top: 5rem;
        margin-bottom: 1rem;
    }
    
    .contact__form .form-section-left {
        width: 75%;
        gap: 2rem;
    }
    
    .contact__form .form-section-right {
        width: 75%;
        gap: 2rem;
    }
    
    .form-div {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }
}






@media screen and (orientation: landscape) and (max-width: 768px) {
    .contact-container {
        padding-top: 3rem;
        height: auto;
    }
    
    .contact-container .title {
        font-size: 3rem;
        margin-bottom: 4rem;
    }
    
    .our-email-button {
        margin-bottom: 4rem;
    }

    .contact__form {
        height: auto;
        font-size: 0.75rem;
    }
    
    .form-section-top {
    }
    
    .form-section-bottom {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }
    
    .contact__form .form-section-left {
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 0.75rem;
    }
    
    .contact__form input {
        height: 20px;
    }
}