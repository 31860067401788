.quote-container {
    background-color: black;
    height: auto;
    width: 100%;
    color: white;
    overflow-x: hidden;

    .segment.title {
        color: white;
        font-size: 3rem;
        align-self: center;
        text-align: center;
        margin: 5rem;
    }

    .quote__form {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        letter-spacing: 2px;
        gap: 8rem;

        margin-top: 10rem;
        margin-bottom: 10rem;
        margin-left: 4rem;
        margin-right: 4rem;

        .origin-dest {
            display: flex;
            flex-direction: row;
            justify-content: center;
        }

        .subtitle {
            align-self: flex-start;
            font-size: 2rem;
            width: 100%;
            text-align: left;
            display: block;
        }

        .origin-container {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            margin-right: 4rem;
            gap: 5rem;

            .subtitle {
                text-align: center;
            }
        }

        .origin-content {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            gap: 4rem;

            .location-input {
                padding: 0.5rem;
                height: 2.7rem;
                box-sizing: border-box;
                width: 100%;
            }
        }

        .dest-container {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            gap: 5rem;
            margin-left: 4rem;

            .subtitle {
                text-align: center;
            }
        }

        .dest-content {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            gap: 4rem;

            .location-input {
                padding: 0.5rem;
                height: 2.7rem;
                box-sizing: border-box;
                width: 100%;
            }
        }

        .appointment {
            display: flex;
            flex-direction: row;

            .date {}

            .time {}
        }

        .load-content {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-self: center;
            gap: 1rem;
            width: 100%;

            .vehicle {

                width: 9rem;

                select {
                    border-radius: 5px;
                    border: none;
                    height: 40px;
                    padding: 0.5rem;
                    color: white;
                    background-color: var(--secondary-color);
                    letter-spacing: 2px;

                    transition: background-color 0.3s ease;
                }

                select:focus {
                    outline: 1px solid var(--primary-color);
                }

                option {
                    background-color: var(--primary-color);
                    font-size: 1rem;
                    padding: 0.5rem;
                }
            }

            .rush {

                text-align: center;
                height: 75px;

                input {
                    width: 38px;
                    height: 40px;
                    align-self: center;
                    margin: 0;

                    &:focus {
                        outline: none;
                    }

                    &:checked {
                        background-color: var(--secondary-color);
                    }
                }

                input:checked {
                    background-color: var(--secondary-color);
                    /* Apply your secondary color */
                }
            }
        }

        .middle-section {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-self: center;
            width: 20rem;
            gap: 4rem;
        }

        .personal {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-self: center;
            gap: 2rem;

            .subtitle {
                margin-bottom: 2rem;
                text-align: center;
            }

            input {
                width: 385px;
            }
        }
    }

    input {
        border-radius: 5px;
        border: none;
        height: 25px;
        padding: 0.5rem;
        color: white;
        background-color: var(--secondary-color);
        letter-spacing: 2px;

        transition: background-color 0.3s ease;

        &:disabled {
            color: #0f162000;
        }
    }

    input:focus {
        outline: 1px solid var(--primary-color);
    }

    .form-div.message {
        height: 100%;
    }

    textarea {
        border-radius: 5px;
        padding: 0.5rem;
        border: none;
        color: white;
        background-color: var(--secondary-color);
        box-sizing: border-box;
        max-width: 100%;
        resize: none;

        transition: all 0.3s ease;
    }

    textarea:focus {
        outline: none;
    }

    button {
        border-radius: 5px;
        border: none;
        height: 40px;
        width: 8rem;
        align-self: center;
        letter-spacing: 2px;
        background-color: white;
        color: black;

        transition: all 0.3s ease;
    }

    button:hover {
        cursor: pointer;
        background-color: var(--primary-color);
        transition-delay: 0.1s;
        transform: scale(1.2);
    }

    button:disabled {
        background-color: rgba(153, 153, 153, 0.483);
        color: white;
        transform: none;
    }

    button:disabled:hover {
        background-color: var(--secondary-color);
    }

    .swal-confirm-button {
        background-color: var(--primary-color);
    }

}

@media screen and (max-width: 550px) {

    .quote-container {
        padding-top: 3rem;


        .segment.title {
            margin: auto;
            margin-top: 5rem;
            font-size: 2.5rem;
        }

        .quote__form {
            gap: 6rem;

            margin-top: 6rem;
            margin-bottom: 6rem;
            margin-left: 4rem;
            margin-right: 4rem;

            .origin-dest {
                flex-direction: column;
                justify-content: space-evenly;
            }

            .origin-container {
                margin-right: 0;
                gap: 3rem;
            }

            .origin-content {
                gap: 2rem;

                .location-input {
                    padding: 1rem;
                }
            }

            .dest-container {
                gap: 3rem;
                margin-left: 0;
                margin-top: 4rem;
            }

            .dest-content {
                gap: 2rem;

                .location-input {
                    padding: 1rem;
                    box-sizing: border-box;
                    width: 100%;
                }
            }

            .appointment {
                justify-content: space-between;

                .date {
                    height: auto;

                    input {
                        height: 2.7rem;
                        width: 8rem;
                    }
                }

                .time {
                    height: auto;

                    input {
                        height: 2.7rem;
                        width: 5rem;
                    }
                }
            }

            .load-content {
                .vehicle {
                    select {
                    }
                }
            }

            textarea {
                padding: 1rem;
            }


            .personal {
                input {
                    width: 250px;
                    font-size: 16px;
                }
            }

            input {
                padding-left: 1rem;
                padding-right: 1rem;
            }

            button:disabled {
                transform: none;
            }
        }
    }
}

@media screen and (orientation: landscape) and (max-width: 768px) {
    .quote-container {
        height: auto;
    
        .segment.title {
            font-size: 3rem;
            margin: 4rem;
        }
    
        .quote__form {
            gap: 4rem;
    
            margin-top: 6rem;
            margin-bottom: 6rem;
    
            .origin-container {
                margin-right: 4rem;
                gap: 2rem;
            }
    
            .origin-content {
                gap: 2rem;
            }
    
            .dest-container {
                gap: 2rem;
                margin-left: 4rem;
            }
    
            .dest-content {
                gap: 2rem;
            }
    
            .appointment {
                .date {
                    height: auto;

                    input {
                        height: 2.7rem;
                        width: 8rem;
                    }
                }

                .time {
                    height: auto;

                    input {
                        height: 2.7rem;
                        width: 5rem;
                    }
                }
            }
    
            .personal {
                gap: 1rem;
    
                .subtitle {
                    margin-bottom: 1rem;
                }
            }
        }
    }
}

/* all iPads */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .segment button:disabled {
    }
}