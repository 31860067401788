.about-container {
    height: 100vh;
    background-color: black;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .title {
        color: white;
        font-size: 3rem;
        align-self: center;
        text-align: center;
    }
}

.about-text {
    font-size: 1rem;
    color: white;
    text-align: center;
    line-height: 2rem;

    width: 85%;

    align-self: center;
}

.about-text em {
    font-size: 1.5rem;
    color: var(--primary-color);
    transition: all 1s ease;
}

.about-text em:hover {
    filter: hue-rotate(90deg);
}

@media screen and (max-width: 550px) {
    .about-container {
        height: 175vh;
    }

    .about-text {
        font-size: 0.85rem;
    }
}

@media screen and (orientation: landscape) and (max-width: 768px) {
    .about-container {
        padding-top: 3rem;
        height: auto;

        .title {
            font-size: 3rem;
            margin-bottom: 6rem;
        }
    }

    .about-text {
        font-size: 0.45rem;
        line-height: 0.75rem;
        width: 85%;

        margin-bottom: 6rem;
    }

    .about-text em {
        font-size: 1.25rem;
    }
}