.header-container {
    opacity: 0;
    filter: blur(10px);

    transition: all 1.75s ease;

}

.header-container {
    text-align: center;
    color: white;

    position: fixed;
    top: 0;
    width: 100vw;

    backdrop-filter: blur(10px);
    height: 4rem;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    z-index: 2;
}

.header-container.show {
    opacity: 1;
    filter: none;
}

.nav-button {
    font-size: 1.2rem;
    margin-right: 1.5vw;
    margin-left: 1.5vw;
    align-content: center;
    height: fit-content;

    border: 1px solid rgba(255, 255, 255, 0.432);
    border-radius: 10px;
    padding: 0.3rem;

    transition: all 0.3s ease;

    &:hover {
        cursor: pointer;
        color: rgb(242, 136, 55);
        border-color: var(--primary-color);
    }
}

a {
    color: inherit;
    text-decoration: none;
}

.quote-button {
    margin-left: auto;
    color: white;
    margin-right: 2rem;
}

@media screen and (max-width: 550px){
    .nav-button {
        font-size: 0.85rem;
        margin: 0;
    }

    .quote-button {
        margin: 0;
    }

    .header-container.show {
        justify-content: space-evenly;
    }
}

@media screen and (orientation: landscape) and (max-width: 768px) {
    
    .header-container {
        height: 3rem;
    }
    
    .nav-button {
        font-size: 0.9rem;
        margin-right: 1.5vw;
        margin-left: 1.5vw;
        align-content: center;
        height: fit-content;
        padding: 0.3rem;
    }
    
    .quote-button {
        margin-left: auto;
        margin-right: 1.5vw;
    }
}